import React, { useState } from "react";
import styled from "styled-components";
import { useTable, useSortBy } from "react-table";
import { Line, Scatter, Doughnut } from "react-chartjs-2";
import ReactSlider from "react-slider";
import { saveState, retrieveState } from "./persistence";

import useGames from "./useGames.js";

const DEFAULT_HOME_COURT_ADVANTAGE = 2.75;

const HEADER_TT_MAP = {
  matchup: "Favorite is highlighted with ✨fun✨",
  spread: "Displayed as Absolute value based on the favorite",
  AdjKPGPFVsSpread:
    "Subtracted KenPom generic points favored, minus the spread, with home court factored in",
  KPGPFVsSpread: "Subtracted KenPom generic points favored, minus the spread",
  KPGPF: "Subtracted KenPom generic points favored",
  KPOffense: "Subtracted KenPom adjusted offensive efficiency",
  KPDefense: "Subtracted KenPom adjusted defensive efficiency",
  KPRank: "Subtracted KenPom ranking",
  KPMatchQuality: "Quality of the name matching when data sourcing",
  AdjInGPFVsSpread:
    "Subtracted Inpredictable generic points favored, minus the spread, with home court factored in",
  InGPFVsSpread:
    "Subtracted Inpredictable generic points favored, minus the spread",
  InGenericPointsFavored: "Subtracted Inpredictable generic points favored",
  InOffense: "Subtracted Inpredictable adjusted offensive efficiency",
  InDefense: "Subtracted Inpredictable adjusted defensive efficiency",
  InRank: "Subtracted Inpredictable rankings",
  InMatchQuality: "Quality of the name matching when data sourcing",
};

const App = () => {
  const [allowed, setAllowed] = useState(
    window.localStorage.getItem("lumpy-sports-princess-logged-in") === "true"
  );
  const [pWord, setPword] = useState("");
  const [stateSelectedSort, setStateSelectedSort] = useState(null);
  const [sport, setSportState] = useState(
    window.localStorage.getItem("lumpy-sports-princess-sport") || "ncaab"
  );

  const savedFilters = retrieveState("filters");
  const [filters, setFilters] = useState(
    savedFilters || {
      periodNcaaf: NCAAF_PERIODS[0],
      periodNcaab: NCAAB_PERIODS[0],
      hcaType: HCA_TYPES[0],
      hcaAmount: DEFAULT_HOME_COURT_ADVANTAGE,
      bettingMetric: BETTING_METRICS[0],
      bettingMetricFilters: [-15, -3, 3, 15],
      spreadFilters: [-30, 0, 0, 30],
    }
  );

  const setFiltersWithCache = (filters) => {
    setFilters(filters);
    saveState("filters", filters);
  };

  const [games, record] = useGames(allowed, sport, filters);
  const [logoSelectorOpen, setLogoSelectorOpen] = useState(false);

  const setSport = (sport) => {
    setSportState(sport);
    window.localStorage.setItem("lumpy-sports-princess-sport", sport);
  };

  const onSubmit = (event) => {
    event.preventDefault();
    if (pWord === "ohmyglob") {
      setAllowed(true);
      window.localStorage.setItem("lumpy-sports-princess-logged-in", true);
    }
    return false;
  };

  const getAccessForm = () => (
    <form autoComplete="off" onSubmit={onSubmit}>
      <StyledInput
        type="password"
        autoFocus={true}
        onChange={(event) => setPword(event.target.value)}
      />
    </form>
  );

  const selectedSort = stateSelectedSort ||
    JSON.parse(window.localStorage.getItem("lumpy-sports-princess-sort")) || {
      id: "time",
      desc: true,
    };

  const selectSort = (column, direction) => {
    const desc = column === selectedSort.id ? !direction : !!direction;
    setStateSelectedSort({ id: column, desc });
    window.localStorage.setItem(
      "lumpy-sports-princess-sort",
      JSON.stringify({ id: column, desc })
    );
  };

  const label = (tooltipItem) => {
    const { teams, spread, ...rest } = games[tooltipItem.index];
    return `${teams[0]} @ ${teams[1]} • Spread: ${spread} • Metric: ${
      rest[filters.bettingMetric]
    }`;
  };

  const backgroundColor = ({ dataIndex }) => {
    const game = games[dataIndex];
    if (!game || !game.outcome) return "#ccc";
    if (game.outcome === "win") return "#61FD84";
    if (game.outcome === "loss") return "#FD616B";
    if (game.outcome === "push") return "#61EBFD";
    return "#e5929d";
  };

  const dataset = {
    datasets: [
      {
        backgroundColor,
        radius: games.length > 100 ? 3 : 5,
        data: games.map((g) => ({
          x: g.spread,
          y: g[filters.bettingMetric],
          matchup: g.matchup,
        })),
      },
    ],
  };

  const breakdown = games.reduce(
    (a, game) => {
      const metricSpread = game[filters.bettingMetric];
      const { spread } = game;
      if (spread === metricSpread) return a;
      if (spread < 0 && metricSpread < 0 && spread < metricSpread)
        return { ...a, favorite: a.favorite + 1 };
      if (spread > 0 && metricSpread > 0 && spread > metricSpread)
        return { ...a, favorite: a.favorite + 1 };
      return { ...a, underdog: a.underdog + 1 };
    },
    { underdog: 0, favorite: 0 }
  );

  return (
    <Container>
      <LogoSelector
        open={logoSelectorOpen}
        onClick={() => setLogoSelectorOpen(!logoSelectorOpen)}
      >
        <Logo
          src="/logo-basketball.png"
          selected={sport === "ncaab"}
          open={logoSelectorOpen}
          onClick={() => setSport("ncaab")}
          first={true}
        />
        <Logo
          src="/logo-football.png"
          selected={sport === "ncaaf"}
          open={logoSelectorOpen}
          onClick={() => setSport("ncaaf")}
        />
      </LogoSelector>
      {games && allowed ? (
        <Layout>
          <div>
            <ScatterContainer>
              <div>
                <Scatter
                  data={dataset}
                  height={80}
                  options={{
                    legend: { display: false },
                    tooltips: { callbacks: { label } },
                  }}
                />
              </div>
              <div>
                <Doughnut
                  options={{
                    legend: { display: false },
                  }}
                  height={200}
                  data={{
                    labels: ["Favorite", "Underdog"],
                    datasets: [
                      {
                        data: [breakdown.favorite, breakdown.underdog],
                        backgroundColor: ["hsl(351, 79%, 20%)", "#e5929d"],
                        borderWidth: 0,
                      },
                    ],
                  }}
                />
              </div>
            </ScatterContainer>
            <Table
              columns={makeColumns(sport)}
              data={games}
              selectedSort={selectedSort}
              selectSort={selectSort}
            />
          </div>
          <FilterMenu
            sport={sport}
            filters={filters}
            setFilters={setFiltersWithCache}
            record={record}
          />
        </Layout>
      ) : allowed ? (
        <StyledSpinner />
      ) : (
        getAccessForm()
      )}
    </Container>
  );
};

const makeColumns = (sport) => {
  const columns = [
    {
      Header: "Line",
      columns: [
        { Header: "Matchup", accessor: "matchup" },
        { Header: "Conference", accessor: "conference" },
        { Header: "Spread", accessor: "spread", sortType: "basic" },
        {
          Header: "Time",
          accessor: (d) => new Date(d.time),
          sortType: "datetime",
          Cell: ({ cell: { value } }) => formatDate(value),
        },
        {
          Header: "Score",
          accessor: "score",
        },
      ],
    },
    {
      Header: "Inpredictable",
      columns: [
        { Header: "AdjGPF/S", accessor: "AdjInGPFVsSpread", sortType: "basic" },
        { Header: "GPF/S", accessor: "InGPFVsSpread", sortType: "basic" },
        // {
        //   Header: "GPF",
        //   accessor: "InGenericPointsFavored",
        //   sortType: "basic",
        // },
        // { Header: "Offense", accessor: "InOffense", sortType: "basic" },
        // { Header: "Defense", accessor: "InDefense", sortType: "basic" },
      ],
    },
  ];

  if (sport === "ncaab") {
    return [
      ...columns,
      {
        Header: "KenPom",
        columns: [
          {
            Header: "AdjGPF/S",
            accessor: "AdjKPGPFVsSpread",
            sortType: "basic",
          },
          { Header: "GPF/S", accessor: "KPGPFVsSpread", sortType: "basic" },
          // { Header: "GPF", accessor: "KPGPF", sortType: "basic" },
          // { Header: "Offense", accessor: "KPOffense", sortType: "basic" },
          // { Header: "Defense", accessor: "KPDefense", sortType: "basic" },
        ],
      },
    ];
  } else {
    return columns;
  }
};

const Stat = styled("span")`
  font-weight: bold;
  color: ${(p) => (p.val === 0 ? "white" : p.val > 0 ? "#99ef99" : "#ff549a")};
`;

const formatDetailtWithTeams = (teams, stats, metricStoreDate) => {
  return (
    <div>
      <div>
        {teams[0]}: <Stat val={stats[0]}>{Number(stats[0]).toFixed(2)}</Stat>
      </div>
      <div>
        {teams[1]}: <Stat val={stats[1]}>{Number(stats[1]).toFixed(2)} </Stat>
      </div>
      <div>
        Difference:
        <Stat val={0}> {Math.abs(stats[0] - stats[1]).toFixed(2)}</Stat>
      </div>
      <em>From: {new Date(metricStoreDate).toLocaleDateString()}</em>
    </div>
  );
};

const getSpreadDetail = (previousSpreads, commenceTime) => {
  const transformedData = previousSpreads
    .slice()
    .reverse()
    .map(({ spread, time_aquired }, i) => {
      return {
        x: new Date(time_aquired * 1000),
        y: Number(spread),
        afterCommence: time_aquired > commenceTime - 10800,
      };
    });

  //hsl(351,79%,75%)

  const colors = transformedData.map((d) => {
    return d.afterCommence ? "hsl(351,79%,20%)" : "hsl(351,79%,75%)";
  });

  return (
    <div style={{ width: "300px", height: "150px" }}>
      <Line
        data={{
          datasets: [
            {
              data: transformedData,
              fill: false,
              backgroundColor: colors,
              borderColor: "hsl(351,79%,75%)",
            },
          ],
          labels: transformedData.map((d, i) =>
            d.x.toLocaleTimeString([], { hour: "numeric" })
          ),
        }}
        width={300}
        height={150}
        options={{
          legend: {
            display: false,
          },
          scales: {
            yAxes: [
              {
                ticks: {
                  maxTicksLimit: 4,
                },
              },
            ],
            xAxes: [
              {
                ticks: {
                  maxTicksLimit: 7,
                },
              },
            ],
          },
        }}
      />
    </div>
  );
};

const getTTDetail = (cell) => {
  const { id } = cell.column;
  const data = cell.row.original;
  const fn = {
    matchup: () => (
      <div>
        <div>🏟️ Locale: {data.stadium}</div>
        <div>📺 Channel: {data.tv ? ` • On: ${data.tv}` : ""}</div>
        <div>🚌 Away Record: {data.awayRecord}</div>
        <div>🏠 Home Record: {data.homeRecord}</div>
      </div>
    ),
    spread: () => getSpreadDetail(data.previousSpreads, data.time),
    AdjKPGPFVsSpread: () =>
      formatDetailtWithTeams(
        data.KPTeams,
        data.AdjKPGPFs,
        data.metricStoreDate
      ),
    KPGPFVsSpread: () =>
      formatDetailtWithTeams(data.KPTeams, data.KPGPFs, data.metricStoreDate),
    KPGPF: () =>
      formatDetailtWithTeams(data.KPTeams, data.KPGPFs, data.metricStoreDate),
    KPOffense: () =>
      formatDetailtWithTeams(
        data.KPTeams,
        data.KPAdjustedOffense,
        data.metricStoreDate
      ),
    KPDefense: () =>
      formatDetailtWithTeams(
        data.KPTeams,
        data.KPAdjustedDefense,
        data.metricStoreDate
      ),
    KPRank: () =>
      formatDetailtWithTeams(
        data.KPTeams,
        data.KPRankings,
        data.metricStoreDate
      ),
    KPMatchQuality: () =>
      formatDetailtWithTeams(
        data.KPTeams,
        data.KPMatchScores,
        data.metricStoreDate
      ),
    AdjInGPFVsSpread: () =>
      formatDetailtWithTeams(
        data.InTeams,
        data.AdjInGPFs,
        data.metricStoreDate
      ),
    InGPFVsSpread: () =>
      formatDetailtWithTeams(data.InTeams, data.InGPFs, data.metricStoreDate),
    InGenericPointsFavored: () =>
      formatDetailtWithTeams(data.InTeams, data.InGPFs, data.metricStoreDate),
    InOffense: () =>
      formatDetailtWithTeams(
        data.InTeams,
        data.InAdjustedOffense,
        data.metricStoreDate
      ),
    InDefense: () =>
      formatDetailtWithTeams(
        data.InTeams,
        data.InAdjustedDefence,
        data.metricStoreDate
      ),
    InRank: () =>
      formatDetailtWithTeams(
        data.InTeams,
        data.InRankings,
        data.metricStoreDate
      ),
    InMatchQuality: () =>
      formatDetailtWithTeams(
        data.InTeams,
        data.KPMatchScores,
        data.metricStoreDate
      ),
  }[id];

  return fn ? fn() : null;
};

const formatDate = (timestamp) => {
  const date = new Date(timestamp * 1000 - 10800 * 1000);
  return new Intl.DateTimeFormat("en-US", {
    weekday: "long",
    hour: "numeric",
    minute: "numeric",
    timeZone: "America/Los_Angeles",
  }).format(date);
};

export default App;

const Table = ({ columns, data, selectedSort, selectSort }) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable(
    {
      columns,
      data,
      initialState: {
        sortBy: [selectedSort],
      },
    },
    useSortBy
  );

  const [headerPopoverOpen, setHeaderPopoverOpen] = useState(null);
  const [cellPopoverOpen, setCellPopoverOpen] = useState(null);

  const renderHeaderCell = (column, onClick) => (
    <CellWrapper
      onClick={() => onClick(column.id)}
      onMouseEnter={() => setHeaderPopoverOpen(column.id)}
      onMouseLeave={() => setHeaderPopoverOpen(null)}
    >
      {column.render("Header")}
      {column.isSorted && (
        <StyledCarat direction={!column.isSortedDesc ? "down" : "up"} />
      )}
    </CellWrapper>
  );

  const renderCell = (cell, i, tt) => {
    return (
      <StyledTd
        {...cell.getCellProps()}
        onMouseEnter={() => setCellPopoverOpen(cell)}
        onMouseLeave={() => setCellPopoverOpen(null)}
      >
        {cell.render("Cell")}
      </StyledTd>
    );
  };

  const onHeaderCellClick = (column) => {
    selectSort(column.id, !selectedSort.direction);
  };

  return (
    <>
      <StyledTable {...getTableProps()}>
        <TableHeader>
          {headerGroups.map((headerGroup) => (
            <StyledTr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <StyledTh
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                >
                  {HEADER_TT_MAP[column.id]
                    ? // <Popover
                      //   positions={["top", "bottom"]}
                      //   isOpen={headerPopoverOpen === column.id}
                      //   content={<span>{HEADER_TT_MAP[column.id]}</span>}
                      //   key={column.id}
                      // >
                      renderHeaderCell(column, onHeaderCellClick)
                    : // </Popover>
                      renderHeaderCell(column, () => onHeaderCellClick(column))}
                </StyledTh>
              ))}
            </StyledTr>
          ))}
        </TableHeader>
        <TableBody {...getTableBodyProps()}>
          {rows.map((row, i) => {
            prepareRow(row);
            return (
              <StyledTr {...row.getRowProps()} key={i}>
                {row.cells.map((cell, i) => renderCell(cell, i))}
              </StyledTr>
            );
          })}
        </TableBody>
      </StyledTable>
      {cellPopoverOpen && <Tooltip cell={cellPopoverOpen} />}
      {headerPopoverOpen && <HeaderTooltip id={headerPopoverOpen} />}
    </>
  );
};

const Tooltip = ({ cell }) => {
  const tt = getTTDetail(cell);
  if (!tt) return null;
  return <FixedTooltip>{tt}</FixedTooltip>;
};

const HeaderTooltip = ({ id }) => {
  const tt = HEADER_TT_MAP[id];
  if (!tt) return null;
  return <FixedTooltip>{tt}</FixedTooltip>;
};

const FixedTooltip = styled.div`
  @keyframes slidein {
    from {
      transform: translateY(20%);
      opacity: 0;
    }

    to {
      transform: translateY(0%);
      opacity: 1;
    }
  }

  position: fixed;
  bottom: 1.75em;
  right: 1.75em;
  padding: 1.6em 1.5em 1.7em 1.5em;
  background: rgba(0, 0, 0, 0.8);
  border-radius: 4px;
  color: #fff;
  z-index: 999;
  max-width: 300px;
  font-size: 15px;
  animation: 0.65s slidein;
  min-width: 200px;
`;

const Container = styled("div")`
  background-color: hsl(351, 79%, 93%);
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100vh;
`;

const LogoSelector = styled("div")`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  padding: 1em;
  margin: -1em;
  margin: 30px 0 20px;
  cursor: pointer;

  &:hover {
    background: rgba(255, 255, 255, 0.2);
    box-shadow: 0 0 1px hsl(351, 79%, 75%);
  }
`;

const Logo = styled("img")`
  width: auto;
  height: 100px;
  display: ${(p) => (p.open || p.selected ? "block" : "none")};
  margin-right: ${(p) => (p.open && p.first ? "1em" : "0")};
`;

const Layout = styled("div")`
  width: calc(100% - 40px);
  margin: 20px;
  display: grid;
  grid-template-columns: 1fr 20%;
  grid-gap: 1em;

  @media (max-width: 1000px) {
    overflow: scroll visible;
    width: calc(100% - 20px);
    margin: 10px;
  }
`;

const StyledTable = styled("table")`
  background-color: hsl(351, 79%, 85%);
  font-size: 12px;
  width: 100%;
`;

const TableBody = styled("tbody")``;

const TableHeader = styled("thead")``;

const CellWrapper = styled("div")`
  width: 100%;
  height: 100%;
`;

const StyledTh = styled("th")`
  background-color: hsl(351, 79%, 75%);
  color: hsl(351, 79%, 20%);
  padding: 8px;
`;

const StyledTd = styled("td")`
  padding: 8px;

  &:hover {
    cursor: default;
    background-color: hsl(351, 79%, 95%);
  }
`;

const StyledTr = styled("tr")`
  border-bottom: 1px solid #333;
  background-color: hsl(351, 79%, 97%);
`;

const StyledInput = styled("input")`
  background: hsl(351, 79%, 98%);
  color: hsl(351, 79%, 20%);
  font-size: 18px;
  padding: 16px;
  margin: 16px;
  min-width: 300px;
  border-radius: 8px;
  box-shadow: none;
  outline: 0;
  border: 0;
`;

const Carat = ({ className }) => (
  <svg viewBox="0 0 74 39" className={className}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M.586.586a2 2 0 012.828 0L37 34.172 70.586.586a2 2 0 112.828 2.828l-35 35a2 2 0 01-2.828 0l-35-35a2 2 0 010-2.828z"
      clipRule="evenodd"
    ></path>
  </svg>
);

const StyledCarat = styled(Carat)`
  transform: rotate(${(p) => (p.direction === "up" ? "180deg" : "0deg")});
  color: hsl(351, 79%, 20%);
  margin-left: 2px;
  top: -2px;
  position: relative;
  width: 8px;
  height: auto;
  display: inline-block;
`;

const Spinner = ({ className }) => (
  <svg viewBox="0 0 100 100" className={className}>
    <path
      fill="hsl(351, 79%, 78%)"
      d="M73 50c0-12.7-10.3-23-23-23S27 37.3 27 50m3.9 0c0-10.5 8.5-19.1 19.1-19.1S69.1 39.5 69.1 50"
    >
      <animateTransform
        attributeName="transform"
        attributeType="XML"
        dur="1s"
        from="0 50 50"
        repeatCount="indefinite"
        to="360 50 50"
        type="rotate"
      ></animateTransform>
    </path>
  </svg>
);

const StyledSpinner = styled(Spinner)`
  width: 100px;
  height: 100px;
  margin-top: 20px;
`;

const NCAAF_PERIODS = [
  "This Week",
  "Last Two Weeks",
  "Last Five Weeks",
  "All Season",
];

const NCAAB_PERIODS = [
  "Today",
  "Next Seven Days",
  "Last Seven Days",
  "Last Fourteen Days",
];

const BETTING_METRICS = ["AdjInEfficiency", "AdjKPEfficiency"];

const HCA_TYPES = ["Auto", "Always On", "Always Off"];

const FilterMenu = ({ sport, filters, setFilters, record }) => {
  const {
    periodNcaaf,
    periodNcaab,
    hcaType,
    hcaAmount,
    bettingMetric,
    bettingMetricFilters,
    spreadFilters,
  } = filters;

  const setFilter = (val, key) => {
    setFilters({ ...filters, [key]: val });
  };

  const ncaaf = sport === "ncaaf";

  return (
    <FilterContainer>
      <FilterSelect
        options={HCA_TYPES}
        label="Home Court Advantage"
        onChange={(e) => setFilter(e.target.value, "hcaType")}
        value={hcaType}
      />
      <FilterSelect
        options={ncaaf ? NCAAF_PERIODS : NCAAB_PERIODS}
        label="Time Period"
        onChange={(e) =>
          setFilter(e.target.value, ncaaf ? "periodNcaaf" : "periodNcaab")
        }
        value={ncaaf ? periodNcaaf : periodNcaab}
      />
      <FilterInput
        label="Home Court Advantage Amount"
        value={hcaAmount}
        onChange={(e) => setFilter(e.target.value, "hcaAmount")}
      ></FilterInput>
      <FilterSlider
        value={spreadFilters}
        onChange={(val) => setFilter(val, "spreadFilters")}
        min={-30}
        max={30}
        step={0.5}
        label="Spread Limits"
      />
      {!ncaaf && (
        <FilterSelect
          options={BETTING_METRICS}
          label="Betting Metric"
          onChange={(e) => setFilter(e.target.value, "bettingMetric")}
          value={bettingMetric}
        />
      )}
      <FilterSlider
        value={bettingMetricFilters}
        onChange={(val) => setFilter(val, "bettingMetricFilters")}
        min={-15}
        max={15}
        label="Betting Metric Limits"
      />
      {record && (
        <div>
          Record: {record.win}-{record.loss}-{record.push} (.
          {((record.win / record.loss) * 500).toFixed(0)})
        </div>
      )}
    </FilterContainer>
  );
};

const FilterSelect = ({ options, label, onChange, className, value }) => {
  return (
    <SelectContainer className={className}>
      <SelectLabel>{label}</SelectLabel>
      <StyledSelect onChange={onChange} value={value}>
        {options.map((option) => (
          <option value={option} key={option}>
            {option}
          </option>
        ))}
      </StyledSelect>
    </SelectContainer>
  );
};

const FilterInput = ({ value, label, onChange, className }) => {
  return (
    <SelectContainer className={className}>
      <SelectLabel>{label}</SelectLabel>
      <StyledFilterInput
        label="Home Court Advantage Amount"
        value={value}
        onChange={onChange}
      ></StyledFilterInput>
    </SelectContainer>
  );
};

const FilterSlider = ({
  label = "Differentials",
  value,
  onChange,
  min = -100,
  max = 100,
  step = 0.25,
}) => {
  const onAfterChange = (val) => onChange(val);
  return (
    <SliderContainer>
      <SelectLabel style={{ marginBottom: "0.6em" }}>{label}</SelectLabel>
      <ReactSlider
        onAfterChange={onAfterChange}
        value={value}
        min={min}
        max={max}
        step={step}
        renderThumb={(props, state) => (
          <Thumb {...props}>
            <ThumbValue>{state.valueNow}</ThumbValue>
          </Thumb>
        )}
        renderTrack={() => <Track />}
        ariaLabel={["Leftmost thumb", "Middle thumb", "Rightmost thumb"]}
      />
    </SliderContainer>
  );
};

const FilterContainer = styled("div")`
  padding: 1em;
  border: 1px solid #eebec5;
  background: #fff;
`;

const ThumbValue = styled.div`
  background: #e5929d;
  padding: 0.25em 0.5em;
  min-width: 50px;
  border-radius: 400px;
  transform: translate(calc(-50% + 2px), calc(-100% - 10px));
  font-size: 12px;
  display: none;
  align-items: center;
  justify-content: center;
`;

const Thumb = styled.div`
  background: hsl(351, 79%, 20%);
  border-radius: 999px;
  width: 8px;
  height: 8px;
  transform: translateY(calc(-50% - 3px));
  border: 4px solid #e5929d;

  &:hover {
    cursor: pointer;

    ${ThumbValue} {
      display: flex;
    }
  }
`;

const Track = styled.div`
  width: 100%;
  height: 1px;
  background: #fbf2f3;
`;

const SelectContainer = styled("div")`
  margin-bottom: 1em;
`;

const SliderContainer = styled(SelectContainer)`
  min-height: 30px;
  margin-bottom: 1.25em;
`;

const SelectLabel = styled("label")`
  font-size: 12px;
  color: #e5929d;
  width: 100%;
  padding-bottom: 0.25em;
  display: block;
`;

const StyledSelect = styled("select")`
  width: 100%;
  background-color: #fbf2f3;
  border-color: #eebec5;
  color: #541319;
  font-size: 16px;
  padding: 0.25em;
`;

const StyledFilterInput = styled("input")`
  width: 100%;
  background-color: #fbf2f3;
  border: 1px solid #eebec5;
  color: #541319;
  font-size: 16px;
  padding: 0.25em;
  box-shadow: none;
  box-sizing: border-box;
`;

const ScatterContainer = styled("div")`
  padding: 1.25em 0em 0.75em 1em;
  margin-bottom: 1.5em;
  display: grid;
  grid-template-columns: 1fr 300px;
  background: #fff;
  border: 1px solid #eebec5;
`;
