import React, { useEffect, useState } from "react";
import styled from "styled-components";
import queryString from "query-string";
import { saveState, retrieveState } from "./persistence";

const HOME_COURT_ADVANTAGE = 3.15;

const useGames = (allowed, sport, filters) => {
  const [data, setData] = useState(0);
  const [selectedGames, setSelectedGames] = useState([]);
  const { periodNcaab, periodNcaaf } = filters;

  useEffect(() => {
    const stashedSelectedGames = retrieveState("selectedGames");
    setSelectedGames(stashedSelectedGames || []);
  }, []);

  useEffect(() => {
    if (!allowed) return;
    fetchGames(sport, setData, periodNcaab, periodNcaaf);
  }, [sport, allowed, periodNcaab, periodNcaaf]);

  if (!data || !data.length) return [[]];

  const [games, record] = makeGames(
    data,
    sport,
    selectedGames,
    setSelectedGames,
    filters
  );
  return [games, record];
};

const getMatchupLine = ({
  game,
  predictedSpread,
  spread,
  teams,
  isSelected,
  selectedGames,
  setSelectedGames,
}) => {
  if (game.verifiedHomeGame && game.spread > 0) {
    return (
      <>
        <Favorite pick={predictedSpread > spread}>{teams[0]}</Favorite> @{" "}
        <Underdog pick={predictedSpread < spread}>{teams[1]}</Underdog>
        <StyledStar
          selected={isSelected}
          onClick={() => selectLine(game, selectedGames, setSelectedGames)}
        />
      </>
    );
  } else if (game.verifiedHomeGame && game.spread < 0) {
    return (
      <>
        <Underdog pick={predictedSpread > spread}>{teams[0]}</Underdog> @{" "}
        <Favorite pick={predictedSpread < spread}>{teams[1]}</Favorite>
        <StyledStar
          selected={isSelected}
          onClick={() => selectLine(game, selectedGames, setSelectedGames)}
        />
      </>
    );
  } else if (!game.verifiedHomeGame && game.spread !== undefined) {
    return (
      <>
        🏆 <Underdog pick={predictedSpread > spread}>{teams[0]}</Underdog> vs{" "}
        <Underdog pick={predictedSpread < spread}>{teams[1]}</Underdog>
        <StyledStar
          selected={isSelected}
          onClick={() => selectLine(game, selectedGames, setSelectedGames)}
        />
      </>
    );
  } else {
    return (
      <>
        ⌛ <Underdog>{teams[0]}</Underdog> vs <Underdog>{teams[1]}</Underdog>
        <StyledStar
          selected={isSelected}
          onClick={() => selectLine(game, selectedGames, setSelectedGames)}
        />
      </>
    );
  }
};

const makeGames = (games, sport, selectedGames, setSelectedGames, filters) => {
  let record = { win: 0, loss: 0, push: 0 };
  const {
    bettingMetric,
    bettingMetricFilters,
    spreadFilters,
    hcaType,
    hcaAmount,
  } = filters;

  const [
    minMetricFilter,
    minMidMetricFilter,
    maxMidMetricFilter,
    maxMetricFilter,
  ] = bettingMetricFilters.sort((a, b) => a - b);

  const [
    minSpreadFilter,
    minMidSpreadFilter,
    maxMidSpreadFilter,
    maxSpreadFilter,
  ] = spreadFilters.sort((a, b) => a - b);

  return [
    games.reduce((acc, game) => {
      const [Ip1, Ip2] = adjustForHomeCourt(
        game.inpredictable,
        hcaType,
        game.verifiedHomeGame,
        hcaAmount
      );

      const adjInEfficiency = calculateDiffStat(
        Ip1,
        Ip2,
        "adjGenericPointsFavored"
      );

      const InEfficiency = calculateDiffStat(Ip1, Ip2, "genericPointsFavored");

      const AdjInGPFVsSpread = calculateAdjustedSpread(
        game.spread,
        adjInEfficiency
      );

      const spread = Number(game.spread);

      let data = {
        spread: game.spread ? spread : "⌛",
        conference:
          Ip1.match.data.conference === Ip2.match.data.conference
            ? Ip2.match.data.conference
            : "OCP",
        stadium: game.stadium,
        tv: game.tv,
        neutralPlay: game.neutralPlay,
        teams: game.teams,
        time: game.commenceTime,
        homeRecord: game.home_record,
        awayRecord: game.away_record,
        previousSpreads: game.previousSpreads,
        AdjInGPFVsSpread,
        AdjInEfficiency: adjInEfficiency,
        InEfficiency,
        InGPFVsSpread: calculateAdjustedSpread(game.spread, InEfficiency),
        InGenericPointsFavored: Number(InEfficiency),
        InGPFs: [Ip1, Ip2].map((k) => k.match.data.genericPointsFavored),
        AdjInGPFs: [Ip1, Ip2].map((k) => k.match.data.adjGenericPointsFavored),
        InOffense: calculateDiffStat(Ip1, Ip2, "adjustedOffense"),
        InAdjustedOffense: [Ip1, Ip2].map((k) => k.match.data.adjustedOffense),
        InDefense: calculateDiffStat(Ip1, Ip2, "adjustedDefence"),
        InAdjustedDefence: [Ip1, Ip2].map((k) => k.match.data.adjustedDefence),
        InTeams: [Ip1, Ip2].map((k) => k.match.data.team),
      };

      if (sport === "ncaab") {
        const [KP1, KP2] = adjustForHomeCourt(
          addPGFtoKenPom(game.kenPom),
          hcaType,
          game.verifiedHomeGame,
          hcaAmount
        );
        const adjKPEfficiency = calculateDiffStat(
          KP1,
          KP2,
          "adjGenericPointsFavored"
        );
        const KPEfficiency = calculateDiffStat(
          KP1,
          KP2,
          "genericPointsFavored"
        );

        data = {
          ...data,
          KPGPFVsSpread: calculateAdjustedSpread(game.spread, KPEfficiency),
          AdjKPGPFVsSpread: calculateAdjustedSpread(
            game.spread,
            adjKPEfficiency
          ),
          AdjKPEfficiency: adjKPEfficiency,
          KPGPF: Number(KPEfficiency),
          KPGPFs: [KP1, KP2].map((k) => k.match.data.genericPointsFavored),
          AdjKPGPFs: [KP1, KP2].map(
            (k) => k.match.data.adjGenericPointsFavored
          ),
          KPOffense: calculateDiffStat(KP1, KP2, "adjustedOffense"),
          KPAdjustedOffense: [KP1, KP2].map(
            (k) => k.match.data.adjustedOffense
          ),
          KPDefense: calculateDiffStat(KP1, KP2, "adjustedDefence") * -1,
          KPAdjustedDefense: [KP1, KP2].map(
            (k) => k.match.data.adjustedDefence
          ),
          KPTeams: [KP1, KP2].map((k) => k.match.data.team),
          metricStoreDate: KP1.match.data.metricStoreDate,
        };
      }

      const actualSpread = Number(game.score[0]) - Number(game.score[1]);
      const predictedSpread = data[bettingMetric];
      const metricDiff = calculateAdjustedSpread(
        game.spread,
        data[bettingMetric]
      );

      if (
        metricDiff < minMetricFilter ||
        (metricDiff > minMidMetricFilter && metricDiff < maxMidMetricFilter) ||
        metricDiff > maxMetricFilter
      ) {
        return acc;
      }

      if (
        game.spread < minSpreadFilter ||
        (game.spread > minMidSpreadFilter &&
          game.spread < maxMidSpreadFilter) ||
        game.spread > maxSpreadFilter
      ) {
        return acc;
      }

      const outcome = () =>
        getOutcome({
          spread,
          predictedSpread,
          actualSpread,
          record,
        });

      const isSelected = selectedGames && selectedGames.includes(makeKey(game));
      const teams = game.teams;

      const matchup = getMatchupLine({
        game,
        predictedSpread,
        spread,
        teams,
        isSelected,
        selectedGames,
        setSelectedGames,
      });

      data = {
        ...data,
        matchup,
        outcome:
          game.score[0] &&
          textOutcome({
            spread,
            predictedSpread,
            actualSpread,
          }),
        score: `${game.score.join("-")} ${game.score[0] ? outcome() : ""}`,
      };

      return [...acc, data];
    }, []),
    record,
  ];
};

const fetchGames = async (sport, setData, periodNcaab, periodNcaaf) => {
  const data = await fetch(
    getUrl(sport, periodNcaab, periodNcaaf)
  ).then((res) => res.json());
  setData(data);
};

const getUrl = (sport = "ncaab", periodNcaab, periodNcaaf) => {
  const query = queryString.stringify({
    period: sport === "ncaab" ? periodNcaab : periodNcaaf,
  });
  return window.location.href.indexOf("localhost") !== -1
    ? `http://localhost:4555/1/games/${sport}?${query}`
    : `/1/games/${sport}?${query}`;
};

const calculateDiffStat = (set1, set2, stat) => {
  const nums = [set1, set2].map((s) => s.match.data[stat]);
  return calculateDiff(nums[0], nums[1]);
};

const calculateAdjustedSpread = (spread, eff) => {
  if (!spread) return 0;
  return Number((Number(spread) - eff).toFixed(2));
};

const adjustForHomeCourt = (teams, hca, verifiedHomeGame, hcaAmount) => {
  const adjustmentValue =
    hca === "Always On" || (hca === "Auto" && verifiedHomeGame)
      ? (hcaAmount || HOME_COURT_ADVANTAGE) / 2
      : 0;

  return teams.map((team, i) => {
    const newTeam = Object.assign({}, team);
    newTeam.match.data.adjGenericPointsFavored =
      i === 1
        ? Number(team.match.data.genericPointsFavored) + adjustmentValue
        : Number(team.match.data.genericPointsFavored) - adjustmentValue;
    return newTeam;
  });
};

const addPGFtoKenPom = (teams) => {
  return teams.map((team) => {
    const { data } = team.match;
    const { adjustedOffense, adjustedDefence, adjustedTempo } = data;

    const perPoss = (num) => Number(num) / 100;

    team.match.data.genericPointsFavored =
      (perPoss(adjustedOffense) - perPoss(adjustedDefence)) * adjustedTempo;

    return team;
  });
};

const textOutcome = ({ spread, predictedSpread, actualSpread }) => {
  if (actualSpread === spread) {
    return "push";
  }

  if (predictedSpread < spread && actualSpread < spread) return "win";
  if (predictedSpread > spread && actualSpread > spread) return "win";
  return "loss";
};

const getOutcome = ({ spread, predictedSpread, actualSpread, record }) => {
  if (actualSpread === spread) {
    record.push = record.push + 1;
    return "👐";
  }

  let win;

  if (predictedSpread < spread && actualSpread < spread) win = true;
  if (predictedSpread > spread && actualSpread > spread) win = true;

  if (win) {
    record.win = record.win + 1;
  } else {
    record.loss = record.loss + 1;
  }

  return win ? "✅" : "❌";
};

const Star = ({ className, onClick }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
    className={className}
    onClick={onClick}
  >
    <polygon points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2"></polygon>
  </svg>
);

const StyledStar = styled(Star)`
  color: ${(p) => (p.selected ? "hsl(45, 100%, 50%)" : "hsl(351, 19%, 65%)")};
  transform: translateY(2px);
  margin-left: 4px;
  width: 12px;
  height: 12px;
  cursor: pointer;

  &:hover {
    color: hsl(45, 100%, 50%);
  }

  polygon {
    fill: ${(p) => (p.selected ? "hsl(45, 100%, 50%)" : "transparent")};
  }
`;

const Favorite = styled("span")`
  font-weight: bold;
  color: #b616a8;

  &:before {
    content: "${(p) => (p.pick ? " 🙌 " : "")}";
  }
`;

const Underdog = styled("span")`
  &:before {
    content: "${(p) => (p.pick ? " 🙌 " : "")}";
  }
`;

const calculateDiff = (x, y) => {
  return Number((Number(x) - Number(y)).toFixed(2));
};

const selectLine = (game, selectedGames, setSelectedGames) => {
  const newItems = !selectedGames.includes(makeKey(game))
    ? [...selectedGames, makeKey(game)]
    : selectedGames.filter((item) => item !== makeKey(game));
  setSelectedGames(newItems);
  saveState("selectedGames", newItems);
};

let WINS = 0;
let LOSSES = 0;

const makeKey = (game) => {
  return game.teams.map((t) => slugify(t)).join("-");
};

const slugify = (text) =>
  text
    .toString()
    .toLowerCase()
    .replace(/\s+/g, "-")
    .replace(/[^\w\-]+/g, "")
    .replace(/\-\-+/g, "-")
    .replace(/^-+/, "")
    .replace(/-+$/, "");

export default useGames;
