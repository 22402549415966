export const saveState = (key, value) => {
  const stringState = window.localStorage.getItem("lumpy-sports-princess");
  const persistedState = stringState ? JSON.parse(stringState) : null;

  window.localStorage.setItem(
    "lumpy-sports-princess",
    JSON.stringify({
      ...persistedState,
      [key]: value,
    })
  );
};

export const retrieveState = (key) => {
  const stringState = window.localStorage.getItem("lumpy-sports-princess");
  const persistedState = stringState ? JSON.parse(stringState) : null;

  if (!persistedState) return undefined;
  return persistedState[key];
};
